import React from "react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Header.css";

const Header = () => {
  const [darkMode, setDarkMode] = useState(false);

  function login() {
    let currentsite = window.location.href;

    let url = new URL(currentsite);
    let code = url.searchParams.get("code");
    if (code) {
      let xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          if (this.response.id_token) {
            console.log(this.response);
            localStorage.setItem("id_token", this.response.id_token);
            localStorage.setItem("access_token", this.response.access_token);
            localStorage.setItem("refresh_token", this.response.refresh_token);
          }
        }
      });
      xhr.open("POST", "https://hpi-new-oncall.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=authorization_code&code=" + code + "&redirect_uri=https://hpi.fujitsu-aws.com&client_id=og2v4gosb1eu66p2pdpp0rdgo");
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.responseType = 'json';
      xhr.send();
    }
    else {

    window.location.replace("https://connect-fujitsu.awsapps.com/start/#/saml/default/HPI-Oncalls/ins-86e170081cf1b311");

    }
  }

  function parsejwtv2(token) {
    let idtoken = token.split(".")[1];
    return JSON.parse(atob(idtoken));
  }

  function checkvalidperiod(tokenobject) {
    // this is for refreshing and logging out
    // modifications needed for the Authtoken post and deletion of the stored IDs
    if (tokenobject) {
      let toeknhere = parsejwtv2(tokenobject);

      if (toeknhere.exp < Date.now() / 1000) {
        let xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.addEventListener("readystatechange", function () {
          if (this.readyState === 4) {
            if (this.response.id_token) {
              console.log(this.response);
              localStorage.setItem("id_token", this.response.id_token);
              localStorage.setItem("access_token", this.response.access_token);
              localStorage.setItem(
                "refresh_token",
                this.response.refresh_token
              );
            } else {
              localStorage.removeItem("id_token");
              localStorage.removeItem("access_token");
              alert("Session expired please log back in");
              window.location.replace(
                "https://connect-fujitsu.awsapps.com/start#/"
              );
            }
          }
        });
        let refreshtoken = localStorage.getItem("refresh_token");
        xhr.open("POST", "https://hpi-new-oncall.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=refresh_token&refresh_token=" + refreshtoken + "&client_id=og2v4gosb1eu66p2pdpp0rdgo");
        xhr.setRequestHeader(
          "Content-Type",
          "application/x-www-form-urlencoded"
        );
        xhr.responseType = "json";
        xhr.send();
      }
    }
  }

  const logout = () => {
    console.log("logging out");
    localStorage.removeItem("id_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    alert("you have been log-out and will return to login page");
    window.location.replace(
      "https://connect-fujitsu.awsapps.com/start#/"
    );
  };

  useEffect(() => {
    login();
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [darkMode]);

  return (
    <>
      <div
        className={`d-flex flex-column flex-shrink-0 text-custom-bg ${
          darkMode ? "dark-mode" : ""
        }`}
        style={{ maxWidth: "280px", minHeight: "100vh" }}
      >
        <div
          className="title-container text-white p-3 d-flex justify-content-center align-items-center"
          style={{ backgroundColor: darkMode ? "#0D6EFD" : "#0D6EFD" }}
        >
          <a href="/" className="text-white text-decoration-none">
            <span className="fs-4">HPI OnCall</span>
          </a>
        </div>

        <div className="p-3 d-flex flex-column flex-grow-1">
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
              <NavLink
                to="/"
                className="nav-link text-white"
                activeClassName="active"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-person-plus"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                  <path
                    fillRule="evenodd"
                    d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                  />
                </svg>{" "}
                Select Agents
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/UpdateNumbers"
                className="nav-link text-white"
                activeClassName="active"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-person-plus"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                  <path
                    fillRule="evenodd"
                    d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                  />
                </svg>{" "}
                Update Phone Number
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/UpdatePhoneNumber"
                className="nav-link text-white"
                activeClassName="active"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-telephone-plus"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                  <path
                    fillRule="evenodd"
                    d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z"
                  />
                </svg>{" "}
                Update a Phone Number
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/AddNewAgent"
                className="nav-link text-white"
                activeClassName="active"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-person-video2"
                  viewBox="0 0 16 16"
                >
                  <path d="M10 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                  <path d="M2 1a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2ZM1 3a1 1 0 0 1 1-1h2v2H1V3Zm4 10V2h9a1 1 0 0 1 1 1v9c0 .285-.12.543-.31.725C14.15 11.494 12.822 10 10 10c-3.037 0-4.345 1.73-4.798 3H5Zm-4-2h3v2H2a1 1 0 0 1-1-1v-1Zm3-1H1V8h3v2Zm0-3H1V5h3v2Z" />
                </svg>{" "}
                Add a New Agent
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/DeleteAgent"
                className="nav-link text-white"
                activeClassName="active"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-person-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z" />
                  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m-.646-4.854.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 0 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 .708-.708" />
                </svg>{" "}
                Delete Agent
              </NavLink>
            </li>
          </ul>
          <div className="mt-auto">
            <a
              href="#"
              className="nav-link text-white logout-button"
              onClick={logout}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-box-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                />
                <path
                  fillRule="evenodd"
                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                />
              </svg>{" "}
              Logout
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
