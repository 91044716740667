import { useEffect, useState } from "react";
import Swal from "sweetalert2";

const DeleteAgent = () => {
    const [users, setUsers] = useState([]);
    const [stringUser, setStringUser] = useState("");
    const [loading, setLoading] = useState(false);

    const requestGetUsers = async () => {
        let myHeaders2 = new Headers();
        myHeaders2.append("Content-Type", "application/json");

        let requestOptions2 = {
            method: 'GET',
            headers: myHeaders2,
            redirect: 'follow'
        };

        await fetch("https://s1obgtk184.execute-api.eu-central-1.amazonaws.com/prod/getagentsinfo", requestOptions2)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setUsers(result);
                setLoading(true);
            })
            .catch(error => console.log('error', error));
    };

    useEffect(() => {
        requestGetUsers();
    }, []);

    const validate = () => {
        if (stringUser === "") {
            Swal.fire('Warning', "Please select an agent", "warning");
        } else {
            confirmDelete();
        }
    };

    const confirmDelete = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUser();
            }
        });
    };

    const deleteUser = () => {
        console.log(stringUser);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "Email": stringUser
        });

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://s1obgtk184.execute-api.eu-central-1.amazonaws.com/prod/awsoncall", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result);
                Swal.fire('Deleted!', 'User deleted successfully!', 'success').then(() => {
                    setStringUser("");  
                    requestGetUsers();  
                });
            })
            .catch(error => console.log('error', error));
    };

    return (
        <div className="margin-auto mt-5">
            <h1>Delete an agent</h1>
            <hr />
            <div className="div-delete-agent">
                <label className="mb-1 mt-1">Agent email</label>
                {loading &&
                    <select value={stringUser} className="form-select" onChange={(e) => { setStringUser(e.target.value.trim()) }}>
                        <option value='' disabled>---</option>
                        {users.map((user, index) => <option key={user.Email.S} value={user.Email.S}>{user.Email.S}</option>)}
                    </select>
                }
                <button onClick={validate} className="btn btn-danger float-right mt-3">Delete</button>
            </div>
        </div>
    );
}

export default DeleteAgent;
