import { useEffect, useState } from "react";
import Swal from "sweetalert2";

const UpdatePhoneNumber = () => {
    const [users, setUsers] = useState([]);
    const [stringUser, setStringUser] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);

    // Función para obtener la lista de usuarios
    const requestGetUsers = async () => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", localStorage.getItem("id_token"));

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        await fetch("https://s1obgtk184.execute-api.eu-central-1.amazonaws.com/prod/getagentsinfo", requestOptions)
            .then(response => response.json())
            .then(result => {
                setUsers(result);
                setLoading(true);
            })
            .catch(error => console.log('error', error));
    };

    // Efecto para cargar los usuarios al montar el componente
    useEffect(() => {
        requestGetUsers();
    }, []);

    // Función de validación
    const validate = () => {
        if (stringUser === "" || phoneNumber === "") {
            Swal.fire('Warning', "Please make sure you filled every input", "warning");
        } else {
            updateInfo();
        }
    };

    // Función para actualizar la información del teléfono
    const updateInfo = () => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", localStorage.getItem("id_token"));

        let raw = JSON.stringify({
            "Email": stringUser,
            "Phone": phoneNumber
        });

        let requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://s1obgtk184.execute-api.eu-central-1.amazonaws.com/prod/awsoncall", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result);
                if (result.includes("Attributes")) {
                    Swal.fire('Success', 'The number has been updated', "success");
                } else {
                    Swal.fire('Error', 'Failed to update phone number', "error");
                }
            })
            .catch(error => console.log('error', error));
    };

    return (
        <div className="margin-auto mt-5">
            <h1>Update a phone number</h1>
            <hr />
            <div className="div-add-phone-number">
                <label className="mb-1 mt-1">Agent email</label>
                {loading && (
                    <select value={stringUser} className="form-select" onChange={(e) => setStringUser(e.target.value.trim())}>
                        <option value='' disabled>---</option>
                        {users.map((user) => (
                            <option key={user.Email.S} value={user.Email.S}>{user.Email.S}</option>
                        ))}
                    </select>
                )}
                <br />
                <label className="mb-1 mt-1" htmlFor="txtPhoneNumber">New phone number</label>
                <input
                    type="text"
                    id="txtPhoneNumber"
                    className="form-control"
                    name="txtPhoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <button onClick={validate} className="btn btn-primary float-right mt-3">Update</button>
            </div>
        </div>
    );
};

export default UpdatePhoneNumber;
