
import { useEffect, useState } from "react";
import Spinner from "./Spinner";

import Swal from "sweetalert2"


const UpdateNumbers = () => {

  let xmlhttp, myObj, myObj2, x, txt = "";

  let selectMonths = ['1','2','3','4','5','6','7','8','9','10','11','12'];
  let selectYears = [];

  function yearsOptions() {
      let currentYear = new Date().getFullYear()
      let max = currentYear + 5
      for (let year = currentYear ; year <= max; year++) {
          selectYears.push(year)
      }
  }

  yearsOptions();

  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [isLoadingPost, setLoadingPost] = useState(false)
  useEffect(() => {
 
    let myHeaders2 = new Headers();
    myHeaders2.append("Authorization", localStorage.getItem("id_token"));
    myHeaders2.append("Content-Type", "application/json");
    let requestOptions = {
        method: 'GET',
        headers: myHeaders2,
        redirect: 'follow'
    };
    fetch("https://s1obgtk184.execute-api.eu-central-1.amazonaws.com/prod/awsoncall", requestOptions)
      .then(response => response.json())
      .then(json => {
        console.log(json)

        setResults(json)
        setLoading(true)
      }
      )
      .catch(error => console.log('error', error));


 
  }, [])

  console.log(results)

  console.log(results.years)

  console.log(results.alldata)

  const getDateFormat = (date) => {
      const index = new Date(date);
      const yyyy = index.getFullYear();
      let mm = index.getMonth() + 1;
      let dd = index.getDate();
      //if (dd < 10) dd = '0' + dd;
      //if (mm < 10) mm = '0' + mm;

      return `${mm}/${dd}/${yyyy}`;
  };

  function addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate());
      result = getDateFormat(result);
      
      return result;
  }




  function GenerateTable() {
    //Build an array containing Customer records.
    let customers = results.alldata;
   
    console.log(customers[0].Schedule.S)
    let headersdata = ["Dates", 'Agent 1 (12:00am - 7:00am)', 'Agent 2 (12:00am - 7:00am)', 'Agent 1 (7:00am - 07:00pm)', 'Agent 2 (7:00am - 07:00pm)', 'Agent 1 (7:00pm - 12:00am)',"Agent 2 (7:00pm - 12:00am)"];


    //Create a HTML Table element.
    let table = document.createElement("TABLE");
    table.classList.add("table-bordered")
    table.border = "1";

    let dayis = 1


    //Get the count of columns.
    let columnCount = headersdata.length;

    //Add the header row.
    let row = table.insertRow(-1);
    for (let i = 0; i < columnCount; i++) {
      let headerCell = document.createElement("th");
      headerCell.setAttribute("id", headersdata[i])
      headerCell.innerHTML = headersdata[i];
      row.appendChild(headerCell);
    }



    //Add the data rows.
    for (let i = 0; i < 31; i++) {
      let days = addDays(`${document.getElementById("months").value}/${dayis}/${document.getElementById("Year").value}`)

      let lastdate = document.getElementById("months").value - 1;
      let current = new Date (days).getMonth();

      if (lastdate < current){
          break
      }

      row = table.insertRow(-1);
      row.setAttribute("id", days)
      let cell = row.insertCell(-1);


      cell.innerHTML = days;
      dayis += 1;
    }


    let dvTable = document.getElementById("dvTable");
    dvTable.innerHTML = "";
    dvTable.appendChild(table);

    dayis = 1;

    for (let i = 0; i < 31; i++) {
      let days = addDays(`${document.getElementById("months").value}/${dayis}/${document.getElementById("Year").value}`)

      let lastdate = document.getElementById("months").value - 1;
      let current = new Date (days).getMonth();

      if (lastdate < current){
          break
      }

      dayis +=1

      addcells(days)
    } 
    current()
  };



  function addcells(usertoaddcells) {
    for (let columns = 0; columns < 6; columns++) {
      let row = document.getElementById(usertoaddcells);

      let x = row.insertCell(-1);
      x.innerHTML = updatestable(usertoaddcells, columns);

    }

  }


  function updatestable(usertoaddcells, columns) {
    txt = ""
    
    txt += "<input id = " + usertoaddcells + " class = " + columns + "\>";

    txt += "</input>"

    return txt
  }

  // function updatestable(usertoaddcells, columns) {
  //   txt = ""
    
  //   txt += "<input id = " + usertoaddcells + " class = " + columns + "\>";

  //   txt += "</input>"

  //   return txt
  // }


  function current() {
    let updater = results.alldata
    console.log(updater)
    for (let y = 0; y < updater.length; y++) {
      if (updater[y]['Month']['N'] === document.getElementById("months").value && updater[y]['Year']['N'] === document.getElementById("Year").value) {
      
        for (let tabls = 0; tabls < 7; tabls++) {
          let element = document.getElementById(updater[y]['Schedule']['S']).cells[tabls]['lastChild']
          // console.log(element, "Entro")
          if (document.getElementById(updater[y]['Schedule']['S']).cells[tabls]['lastChild']['className'] === "0") {
              if(updater[y] && updater[y]['agent1']){
              element.value = updater[y]['agent1']['S']
              }else{ 
                element.value = ""
              }
              //addcells(updater[y]['agent1']['S'])

          } else if (document.getElementById(updater[y]['Schedule']['S']).cells[tabls]['lastChild']['className'] === "1") {
            if(updater[y] && updater[y]['agent2']){
            element.value = updater[y]['agent2']['S']
            }else{ 
              element.value = ""
            }
            //addcells(updater[y]['agent1']['S'])

        }  else if (document.getElementById(updater[y]['Schedule']['S']).cells[tabls]['lastChild']['className'] === "2") {
          if(updater[y] && updater[y]['agent3']){
          element.value = updater[y]['agent3']['S']
          }else{ 
            element.value = ""
          }
        }  else if (document.getElementById(updater[y]['Schedule']['S']).cells[tabls]['lastChild']['className'] === "3") {
          if(updater[y] && updater[y]['agent4']){
          element.value = updater[y]['agent4']['S']
          }else{ 
            element.value = ""
          }
        }  else if (document.getElementById(updater[y]['Schedule']['S']).cells[tabls]['lastChild']['className'] === "4") {
          if(updater[y] && updater[y]['agent5']){
          element.value = updater[y]['agent5']['S']
          }else{ 
            element.value = ""
          }
        }  else if (document.getElementById(updater[y]['Schedule']['S']).cells[tabls]['lastChild']['className'] === "5") {
          if(updater[y] && updater[y]['agent6']){
          element.value = updater[y]['agent6']['S']
          }else{ 
            element.value = ""
          }
          //addcells(updater[y]['agent1']['S'])

      } 

        }
      }
    }
  }




  function ClearMonth() {
    document.getElementById("months").value = "Select Month"
  }
  //console.log(results)


  function getvaluess() {
    let data = [];
    let customers = results.alldata;

    let dayis = 1;

    for (let i = 0; i < 31; i++) {
        let days = addDays(`${document.getElementById("months").value}/${dayis}/${document.getElementById("Year").value}`)
        
        let lastdate = document.getElementById("months").value - 1;
        let current = new Date (days).getMonth();

        if (lastdate < current){
            console.log("ERROR")
            break
        }
        dayis+=1

        if (document.getElementById("months").value && document.getElementById("Year").value) {
            // collect data for each of the 3 columns
            let rowData = {
              Date: days,
              agent1: "",
              agent2: "",
              agent3: "",
              agent4: "",
              agent5: "",
              agent6: "",
            };
      
            for (let tabls = 0; tabls < 7; tabls++) {
              let cellValue = document.getElementById(days).cells[tabls]['lastChild'].value;
              let cellClass = document.getElementById(days).cells[tabls]['lastChild']['className'];
      
              if (cellClass === "0") {
                rowData.agent1 = cellValue;
              } else if (cellClass === "1") {
                rowData.agent2 = cellValue;
              }else if (cellClass === "2") {
                rowData.agent3 = cellValue;
              }
              else if (cellClass === "3") {
                rowData.agent4 = cellValue;
              }
              else if (cellClass === "4") {
                rowData.agent5 = cellValue;
              }
              else if (cellClass === "5") {
                rowData.agent6 = cellValue;
              }
            }
      
            // add the row data to the array
            data.push(rowData);
        }
    }
  
    return data
  }


 
  function sendittoserver() {
    setLoadingPost(true)
    console.log("Hola estoy en select agents ")
    let json = getvaluess()
    //console.log("hola " + json[0].Date)

    //console.log("hola " + json[0].agent1)

       

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("id_token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ json });


    var requestOptions = {
        method: 'POST',
        headers: {"Authorization": localStorage.getItem("id_token"),
        "Content-Type": "application/json"
    },
        body: raw,
        redirect: 'follow'
    };

    fetch("https://s1obgtk184.execute-api.eu-central-1.amazonaws.com/prod/postawsoncall", requestOptions)
        .then(response => response.text())
        .then(result => { Swal.fire("The data has been added in the system")
            setLoadingPost(false)
        })
        .catch(error => console.log('error', error));
    

  }



  return (

<div className="margin-auto">
<div>
 
  <h2 className="title">Please select the month and year to build the table</h2>
  
</div>
{
       loading &&
          <div className="select-div">
          <select id="Year" className="form-select" onChange={ClearMonth}>
            <option value='' selected="selected" disabled>---</option>
            {selectYears && (selectYears.map(year => <option key={year} value={year}>{year}</option>))}

          </select>

          <select id="months" className="form-select" onChange={GenerateTable}>
            <option value='' selected="selected" disabled>---</option>
            {selectMonths && (selectMonths.map(months => <option key={months} value={months}>{months}</option>))}

          </select>
        </div>
   
       
      }
     

      <div id="Deploy">
      <button onClick={sendittoserver} className="btn btn-success float-right mb-3 mt-3">Deploy Schedules</button>
      

      {isLoadingPost && <>
        <br/>
        <br/>
            <Spinner/>
            <br/>
            <br/>
            </>}
      </div>
      
      {/* <button onClick="sendittoserver()">Deploy Schedules</button> */}


    




      <div id="dvTable" className="divtable-class mb-5"></div>

    

    
</div>
  );
}

export default UpdateNumbers;