import { useEffect, useState } from "react";

import Swal from "sweetalert2"

const AddPhone = () => {

    const [users, setUsers] = useState([])
    const [stringEmail, setStringEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")

    const validate = () => {
        if (stringEmail === "" || phoneNumber === "") {
            Swal.fire('Warning', "Please make sure you filled every input", "warning")
        } else {
            addEmailPhone()
        }
    }

    const addEmailPhone = () => {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", localStorage.getItem("id_token"));
        var raw = JSON.stringify({
            "Email": stringEmail,
            "Phone": phoneNumber
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://s1obgtk184.execute-api.eu-central-1.amazonaws.com/prod/awsoncall", requestOptions)
            .then(response => response.text())
            .then(result => { console.log(result)
                Swal.fire('Information', result, "info")
            })
            .catch(error => console.log('error', error));

    }

    return (

        <div className="margin-auto mt-5">
            <h1 className="text-align">Add a new agent</h1>
            <hr />


            <div className="div-add-phone-number">

            
            <label htmlFor='txtAgentEmail' className="mb-1 mt-1">Agent email</label>
           
            <input type='text' id="txtAgentEmail" className="form-control"  name="txtAgentEmail" value={stringEmail} onChange={(e) => { setStringEmail(e.target.value) }}></input>
            <br />
            <label className="mb-1 mt-1" htmlFor="txtPhoneNumber">Phone number</label>
            <input type='text' id="txtPhoneNumber" className="form-control"  name="txtPhoneNumber" value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }}></input>
            <button onClick={validate} className="btn btn-success float-right mt-3">Create</button>
            </div>
        </div>
    );
}

export default AddPhone;